<template>
  <div>
    <b-row>
      <b-col sm="3">
        <b-form-datepicker placeholder="From date" reset-button v-model="startDate" />
      </b-col>

      <b-col sm="2">
        <b-form-timepicker :disabled="!startDate" placeholder="From time" reset-button v-model="startTime" />
      </b-col>
    </b-row>

    <b-row style="margin-top: 1rem">
      <b-col sm="3">
        <b-form-datepicker placeholder="To date" reset-button v-model="endDate" />
      </b-col>

      <b-col sm="2">
        <b-form-timepicker :disabled="!endDate" placeholder="To time" reset-button v-model="endTime" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { format } from 'date-fns';
export default {
  components: {},
  props: ['defaultStartDate', 'defaultEndDate', 'defaultStartTime', 'defaultEndTime'],

  data() {
    return {
      startDate: this.defaultStartDate,
      startTime: this.defaultStartTime,
      endDate: this.defaultEndDate,
      endTime: this.defaultEndTime
    };
  },

  computed: {
    form() {
      return this.$store.getters.getForm;
    }
  },

  mounted() {
    this.startDateTime = this.form.filter.startDateTime;
    this.endDateTime = this.form.filter.endDateTime;
    if (this.startDateTime) {
      this.startDate = format(this.startDateTime, 'yyyy-MM-dd');
      this.startTime = format(this.startDateTime, 'HH:mm:ss');
    }

    if (this.endDateTime) {
      this.endDate = format(this.endDateTime, 'yyyy-MM-dd');
      this.endTime = format(this.endDateTime, 'HH:mm:ss');
    }
  },

  watch: {
    startDate: function () {
      if (!this.startDate) {
        this.startTime = null;
      }

      this.datesChanged();
    },
    startTime: function () {
      this.datesChanged();
    },
    endDate: function () {
      if (!this.endDate) {
        this.endTime = null;
      }

      this.datesChanged();
    },
    endTime: function () {
      this.datesChanged();
    }
  },

  methods: {
    datesChanged() {
      let startDateTime = null;
      let endDateTime = null;

      if (this.startDate) {
        startDateTime = this.startDate + (this.startTime ? `T${this.startTime}` : '');
      }

      if (this.endDate) {
        endDateTime = this.endDate + (this.endTime ? `T${this.endTime}` : '');
      }

      this.$emit('datesChanged', {
        startDateTime: startDateTime ? this.parseISO(startDateTime) : null,
        endDateTime: endDateTime ? this.parseISO(endDateTime) : null
      });
    },
    reset() {
      this.startDate = null;
      this.startTime = null;
      this.endDate = null;
      this.endTime = null;
    }
  }
};
</script>
