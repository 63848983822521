<template>
  <vue-ellipse-progress
    :legendValue="currentProgress"
    :color="borderColor"
    :colorFill="fillColor"
    :progress="progress"
    :size="50"
  >
    <template #legend-value>
      <span slot="legend-value">/{{ totalProgress }}</span>
    </template>
  </vue-ellipse-progress>
</template>

<script>
import { VueEllipseProgress } from 'vue-ellipse-progress';

export default {
  components: {
    VueEllipseProgress
  },
  props: {
    fillColor: {
      type: String,
      default: '#E9ECEF' // bg-gray-200
    },
    borderColor: {
      type: String,
      default: '#15803d' // bg-green-700
    },
    totalProgress: {
      type: Number,
      default: 0
    },
    currentProgress: {
      type: Number
    }
  },
  computed: {
    progress() {
      return this.totalProgress ? (this.currentProgress / this.totalProgress) * 100 : 0;
    }
  }
};
</script>
