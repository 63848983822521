<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      track-by="value"
      label="name"
      :searchable="true"
      :allow-empty="true"
      placeholder="Select a status"
      @remove="reset"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: { Multiselect },

  props: {
    status: {
      type: String,
      default: null,
      optional: true
    }
  },

  data() {
    return {
      selected: null,
      options: [
        { name: 'All', value: 'ALL' },
        { name: 'Active', value: 'ACTIVE' },
        { name: 'Inactive', value: 'INACTIVE' },
        { name: 'Pending', value: 'PENDING' },
        { name: 'Error', value: 'FAILED' },
        { name: 'Void', value: 'VOID' }
      ]
    };
  },

  watch: {
    selected: function () {
      if (this.selected == null) {
        this.$emit('orderStatusFilter', null);
      } else {
        this.$emit('orderStatusFilter', this.selected.value === 'ALL' ? null : this.selected.value);
      }
    },
    status: function () {
      if (this.status == null) {
        this.selected = null;
      }
    }
  },

  mounted() {
    if (this.status == null) {
      this.selected = null;
    } else {
      this.selected = this.options.find((option) => option.value === this.status);
    }
  },

  methods: {
    reset() {
      this.selected = null;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
