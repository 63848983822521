<template>
  <div>
    <b-alert v-if="error" show dismissible variant="danger">{{ error }}</b-alert>
    <b-spinner v-if="loading && !error">Loading Station List</b-spinner>

    <multiselect
      v-else
      v-model="selected"
      :options="options"
      label="name"
      track-by="id"
      :searchable="true"
      :allow-empty="true"
      placeholder="Select a station"
      @remove="reset"
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import StationService from '@/lib/station-service';

export default {
  components: { Multiselect },
  props: {
    station: {
      type: Object,
      default: null,
      optional: true
    }
  },

  data() {
    return {
      error: null,
      loading: false,
      selected: this.station ? this.station : null,
      options: [{ name: 'All Stations', id: 'All Stations' }]
    };
  },

  watch: {
    selected: function () {
      this.$emit('stationSelected', this.selected?.name == 'All Stations' ? null : this.selected);
    },
    station: function () {
      if (this.station == null) {
        this.selected = { name: 'All Stations', id: 'All Stations' };
      }
    }
  },

  created() {
    this.loading = true;

    const params = {
      pageSize: 50
    };
    StationService.listStations(params)
      .then((response) => {
        response.data.forEach((event) => {
          this.options.push(event);
        });
        this.loading = false;
      })
      .catch((error) => {
        this.error = error;
      });
  },

  methods: {
    reset() {
      this.selected = null;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
