<template>
  <div>
    <div>
      <BaseButton v-b-modal.create-order-v2-modal variant="secondary-outline" :disabled="disabled">
        <span>Create Order</span>
      </BaseButton>
    </div>

    <!-- Bootstrap modal here because our GenericModal doesn't handle scrolling well enough yet -->
    <b-modal
      id="create-order-v2-modal"
      title="Create Order V2"
      v-model="modalShow"
      @show="resetForm"
      @hidden="resetForm"
      no-close-on-backdrop
      size="xl"
      body-class="position-static"
    >
      <div>
        <Alert variant="red" icon="exclamation" v-if="errorMessage">{{ errorMessage }}</Alert>
        <Alert variant="green" icon="exclamation" v-if="success">Order(s) created successfully!</Alert>
      </div>
      <!-- Raffle and Ticket Details -->
      <div v-show="currentPage === 1">
        <OrderDetailsForm
          @updateOrderDetails="updateOrderDetails"
          :editOrderDetails="orderDetails"
          action="create"
          ref="orderDetails"
        />
        <AddOrderItemForm @addToCart="addToCart" :options="eventOptions" />
        <CreateOrderCart
          :cartItems="cartItems"
          :donationAmount="donationAmount"
          @updateDonation="updateDonation"
          @deleteItem="removeFromCart"
        />
      </div>

      <!-- Customer Information -->
      <div v-show="currentPage === 2">
        <CustomerInformationForm
          @updateCustomerInformation="updateCustomerInformation"
          :editCustomerInformation="customerInformation"
          :required="true"
          ref="customerInformation"
        />
      </div>

      <!-- Footer -->
      <template #modal-footer>
        <div class="flex flex-wrap gap-2 justify-center sm:justify-between w-100">
          <!-- Pagination Indicator -->
          <div class="flex items-center">
            <p class="font-medium mr-2">{{ pageName }}</p>
            <CircleNav :totalProgress="2" :currentProgress="currentPage" />
          </div>

          <!-- Navigation buttons -->
          <div class="flex">
            <!-- Page 1 Navigation - Close, Next -->
            <div class="flex" v-if="currentPage === 1">
              <BaseButton variant="secondary" :disabled="disabled" @click.native="close()"> Close </BaseButton>
              <BaseButton variant="success-outline" :disabled="disableNext" class="ml-2" @click.native="updatePage(2)">
                <span>Next</span>
              </BaseButton>
            </div>

            <!-- Page 2 Navigation - Close, Back, Create Another, Create and Close -->
            <div class="flex flex-wrap gap-y-2 justify-center" v-if="currentPage === 2">
              <BaseButton variant="secondary" :disabled="disabled" @click.native="close()"> Close </BaseButton>
              <BaseButton variant="secondary-outline" :disabled="disabled" class="ml-2" @click.native="updatePage(1)">
                Back
              </BaseButton>
              <BaseButton
                variant="success-outline"
                :disabled="disableCreate"
                :loading="loadingCreateAnother"
                class="ml-2"
                @click.native="submit()"
              >
                Create Another
              </BaseButton>
              <BaseButton
                variant="success"
                :disabled="disableCreate"
                :loading="loadingCreateAndClose"
                class="ml-2"
                @click.native="submit('createAndClose')"
              >
                Create and close
              </BaseButton>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Alert from '@/components/ui/Alert';
import CircleNav from '@/components/ui/CircleNav';
import OrderDetailsForm from '@/components/forms/OrderDetailsForm';
import CustomerInformationForm from '@/components/forms/CustomerInformationForm';
import AddOrderItemForm from '@/components/forms/AddOrderItemForm';
import CreateOrderCart from '@/components/CreateOrderCart.vue';
import { parseISO, set } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { v4 as uuidv4 } from 'uuid';

import EventServiceV2 from '@/lib/event-service-v2';
import PaymentService from '@/lib/payment-service';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  components: {
    Alert,
    CircleNav,
    OrderDetailsForm,
    CustomerInformationForm,
    AddOrderItemForm,
    CreateOrderCart,
    BaseButton
  },
  data() {
    return {
      modalShow: false,
      page: 1,
      disabled: false,
      orderDetails: {
        orderType: null,
        orderStatus: null,
        orderSource: null,
        orderReceivedDate: null,
        referenceId: null,
        batchId: null
      },
      customerInformation: {
        firstName: null,
        lastName: null,
        title: null,
        age: null,
        address: null,
        city: null,
        province: null,
        postalCode: null,
        email: null,
        phone: null,
        additionalNames: null,
        comment: null,
        shippingAddressLine1: null,
        shippingCity: null,
        shippingState: null,
        shippingPostal: null
      },
      cartItems: [],
      purchasedEvents: [],
      eventOptions: [],
      donationAmount: 0,
      errorMessage: null,
      loadingCreateAnother: false,
      loadingCreateAndClose: false,
      success: false
    };
  },
  async mounted() {
    const params = {
      status: 'not_ended_and_active',
      childEvents: true,
      pageSize: 50
    };

    try {
      const optionsEvents = [];

      optionsEvents.push({ value: null, text: '--- Select a Raffle ---' });

      const response = await EventServiceV2.listEvents(params);

      for (const event of response.data) {
        optionsEvents.push({
          value: event.id,
          text: event.name,
          ticketPackages: event.ticketPackages,
          event: event,
          disabled: false
        });
      }

      this.eventOptions = optionsEvents;
    } catch (error) {
      this.errorMessage = this.parseError(error).message;
    }
  },
  computed: {
    pageName() {
      if (this.currentPage == 1) {
        return 'Raffle and Ticket Details';
      } else {
        return 'Customer Information';
      }
    },
    currentPage() {
      return this.page;
    },
    disableNext() {
      if (this.currentPage == 1) {
        return (
          this.cartItems.length === 0 || this.orderDetails.orderType === null || this.orderDetails.orderStatus === null
        );
      }
      return false;
    },
    disableCreate() {
      const silent = true; // Validate without showing errors on fields
      return !this.$refs.customerInformation.customValidation(silent);
    }
  },
  methods: {
    showAlert() {
      setTimeout(() => {
        this.success = false;
      }, 3000);
    },
    updatePage(newPage) {
      this.page = newPage;
    },
    updateOrderDetails(orderDetails) {
      this.orderDetails = orderDetails;
    },
    updateCustomerInformation(customerInformation) {
      this.customerInformation = customerInformation;
    },
    updateDonation(donation) {
      this.donationAmount = donation;
    },
    addToCart(event, ticketPackages, quantities) {
      const selectedPackages = [];

      for (const selection in quantities) {
        const tp = ticketPackages.find((pkg) => pkg.id === selection);
        selectedPackages.push({
          ...tp,
          quantity: Number(quantities[tp.id])
        });
      }

      this.cartItems.push({
        event,
        ticketPackages: selectedPackages
      });
      this.purchasedEvents.push(event.id);

      // Set donationEnabled prop on cartItems to determine if donations are enabled for the order group
      this.cartItems.donationEnabled = this.cartItems.some((item) => item.event.settings.donationEnabled);

      const { id, parentEventId } = event;

      let disabledOptions = [];

      // Look for other child events
      if (parentEventId) {
        disabledOptions = this.eventOptions.map((event) => {
          // Account for no option
          if (event.value == null) {
            return event;
          }

          // Account for already purchased events
          if (this.purchasedEvents.includes(event.event.id)) {
            return { ...event, disabled: true };
          }

          if (event.event.id === parentEventId) {
            return event;
          }

          if (event.event.parentEventId !== parentEventId) {
            return { ...event, disabled: true };
          } else {
            return event;
          }
        });
      } else {
        // Look for children of the paernt
        disabledOptions = this.eventOptions.map((event) => {
          // Account for no option
          if (event.value == null) {
            return event;
          }

          // Account for already purchased events
          if (this.purchasedEvents.includes(event.event.id)) {
            return { ...event, disabled: true };
          }

          if (event.event.parentEventId !== id) {
            return { ...event, disabled: true };
          } else {
            return event;
          }
        });
      }

      this.eventOptions = disabledOptions;
    },
    removeFromCart(row) {
      this.cartItems = this.cartItems.filter((item) => item.event.id !== row.event.id);
      const filtered = this.purchasedEvents.filter((id) => id !== row.event.id);
      this.purchasedEvents = filtered;

      // Update donationEnabled prop on cartItems in case event with donation enabled was removed
      this.cartItems.donationEnabled = this.cartItems.some((item) => item.event.settings.donationEnabled);

      if (this.purchasedEvents.length == 0) {
        // Enable all options if cart is cleared
        this.eventOptions = this.eventOptions.map((event) => {
          return { ...event, disabled: false };
        });
      } else {
        // Enable the option that was removed from the cart
        this.eventOptions = this.eventOptions.map((event) => {
          if (event.value === row.event.id) {
            return { ...event, disabled: false };
          }
          return event;
        });
      }
    },
    validateAll() {
      const validOrderDetails = this.$refs.orderDetails.$validator.validateAll();
      const validCustomerInformation =
        this.$refs.customerInformation.customValidation() && this.$refs.customerInformation.$validator.validateAll();

      return validOrderDetails && validCustomerInformation;
    },
    resetValidation() {
      this.$refs.orderDetails.$validator.reset();
      this.$refs.customerInformation.resetValidation();
    },
    async submit(typeOfSubmit) {
      try {
        this.errorMessage = null;

        if (!this.validateAll()) {
          this.errorMessage = 'Please fill out all required fields.';
          return;
        }

        if (typeOfSubmit === 'createAndClose') {
          this.loadingCreateAndClose = true;
        } else {
          this.loadingCreateAnother = true;
        }

        // Convert the date to UTC
        let dateTime;

        if (this.orderDetails.orderReceivedDate) {
          if (this.orderDetails.orderReceivedDate.includes('T')) {
            dateTime = this.orderDetails.orderReceivedDate;
          } else {
            const date = set(parseISO(this.orderDetails.orderReceivedDate), {
              hours: 0,
              minutes: 0,
              seconds: 0,
              milliseconds: 0
            });

            const utcToZonedTime = zonedTimeToUtc(date, this.$store.getters.getOrganization.timeZone).toISOString();

            dateTime = utcToZonedTime;
          }
        }

        // Format cart items
        const cartItems = [];

        for (const item of this.cartItems) {
          const packages = item.ticketPackages.map((pkg) => {
            return {
              ticketPackageId: pkg.id,
              numPackages: pkg.quantity
            };
          });

          for (const pkg of packages) {
            if (pkg.numPackages > 0) {
              cartItems.push({
                ticketPackageId: pkg.ticketPackageId,
                numPackages: pkg.numPackages
              });
            }
          }
        }

        // Get child raffle with parent event ID
        const childRaffle = this.cartItems.find((item) => {
          return item.event.parentEventId !== null;
        });

        // Get parent raffle to use for the eventId if this is the only raffle ordered from
        const parentRaffle = this.cartItems.find((item) => {
          return item.event.childEvents.length !== 0;
        });

        // Handle the case where there there is no parent/child relationship on the raffle in the cart
        const eventId =
          !childRaffle && !parentRaffle
            ? this.cartItems[0].event.id
            : childRaffle?.event.parentEventId || parentRaffle?.event.id;

        // Create order object
        const order = {
          eventId,
          purchaser: {
            firstName: this.customerInformation.firstName,
            lastName: this.customerInformation.lastName,
            secondaryName: this.isStringEmpty(this.customerInformation.additionalNames),
            email: this.customerInformation.email || undefined,
            phone: this.customerInformation.phone || undefined,
            address: this.customerInformation.address || undefined,
            city: this.customerInformation.city || undefined,
            province: this.customerInformation.province || undefined,
            postal: this.customerInformation.postalCode?.replace(/\s/g, '') || undefined,
            age: this.isStringEmpty(this.customerInformation.age),
            title: this.isStringEmpty(this.customerInformation.title),
            shippingEnabled: this.customerInformation.shippingEnabled
          },
          source: this.orderDetails.orderSource || undefined,
          paymentType: this.orderDetails.orderType,
          comment: this.customerInformation.comment || undefined,
          cartItems: cartItems,
          pending: this.orderDetails.orderStatus === 'pending',
          donationAmountCents: this.donationAmount ? this.donationAmount * 100 : 0,
          batchId: this.orderDetails.batchId || undefined,
          referenceId: this.isStringEmpty(this.orderDetails.referenceId),
          receivedDate: dateTime ?? undefined,
          clientTxId: uuidv4()
        };

        // If shipping address is filled out use it, otherwise use billing addresss
        if (!this.customerInformation.shippingEnabled) {
          order.purchaser.shippingAddressLine1 = this.customerInformation.shippingAddressLine1 || undefined;
          order.purchaser.shippingCity = this.customerInformation.shippingCity || undefined;
          order.purchaser.shippingState = this.customerInformation.shippingState || undefined;
          order.purchaser.shippingPostal = this.customerInformation.shippingPostal?.replace(/\s/g, '') || undefined;
        } else {
          order.purchaser.shippingAddressLine1 = this.customerInformation.address || undefined;
          order.purchaser.shippingCity = this.customerInformation.city || undefined;
          order.purchaser.shippingState = this.customerInformation.province || undefined;
          order.purchaser.shippingPostal = this.customerInformation.postalCode?.replace(/\s/g, '') || undefined;
        }

        // Hold these options if the user wants to create another order
        const savedForAnother = {
          orderType: this.orderDetails.orderType,
          orderStatus: this.orderDetails.orderStatus,
          orderReceivedDate: this.orderDetails.orderReceivedDate,
          batchId: this.orderDetails.batchId
        };

        // Call the API to create the order

        await PaymentService.createOrder(order);

        // Handle post-submit actions
        if (typeOfSubmit === 'createAndClose') {
          this.loadingCreateAndClose = false;
          this.close();
          this.$emit('triggerRefresh', {
            text: 'Order(s) created successfully!',
            variant: 'success',
            model: true
          });
        } else {
          this.resetForm();
          this.$refs.customerInformation.resetValidation();
          this.loadingCreateAnother = false;
          this.success = true;
          this.showAlert();
          this.$emit('triggerRefresh');

          this.orderDetails.orderType = savedForAnother.orderType;
          this.orderDetails.orderStatus = savedForAnother.orderStatus;
          this.orderDetails.orderReceivedDate = savedForAnother.orderReceivedDate;
          this.orderDetails.batchId = savedForAnother.batchId;

          this.updatePage(1);
        }
      } catch (error) {
        this.loadingCreateAndClose = false;
        this.loadingCreateAnother = false;
        this.errorMessage = this.parseError(error).message;
      }
    },
    close() {
      this.resetForm();
      this.$emit('triggerRefresh');
      this.$bvModal.hide('create-order-v2-modal');
    },
    resetForm() {
      this.page = 1;
      this.orderDetails = {
        orderType: null,
        orderStatus: null,
        orderSource: null,
        orderReceivedDate: null,
        referenceId: null,
        batchId: null
      };
      this.customerInformation = {
        firstName: null,
        lastName: null,
        title: null,
        age: null,
        address: null,
        city: null,
        province: null,
        postalCode: null,
        email: null,
        phone: null,
        additionalNames: null,
        comment: null,
        shippingAddressLine1: null,
        shippingCity: null,
        shippingState: null,
        shippingPostal: null,
        shippingEnabled: false
      };
      this.cartItems = [];
      this.donationAmount = 0;
      this.eventOptions = this.eventOptions.map((event) => {
        return { ...event, disabled: false };
      });
      this.purchasedEvents = [];
    }
  }
};
</script>

// Our overall app text color keeps getting overridden and idk why but I'm brute forcing it here for now
<style>
#create-order-v2-modal {
  color: #2c3e50;
}
</style>
