<template>
  <div>
    <b-alert v-if="error" show dismissible variant="danger">{{ error }}</b-alert>
    <b-spinner v-if="loading && !error">Loading Device List</b-spinner>

    <multiselect
      v-else
      v-model="selected"
      :options="options"
      label="name"
      track-by="id"
      :searchable="true"
      :allow-empty="true"
      placeholder="Select a device"
      @remove="reset"
    >
    </multiselect>
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import Multiselect from 'vue-multiselect';

import DeviceServiceV2 from '@/lib/device-service-v2';

export default {
  components: { Multiselect },

  props: {
    device: {
      type: Object,
      default: null,
      optional: true
    }
  },

  data() {
    return {
      error: null,
      loading: false,
      selected: this.device ? this.device : null,
      options: [{ name: 'All Devices', id: 'All Devices' }]
    };
  },

  watch: {
    selected: function () {
      this.$emit('deviceSelected', this.selected?.name == 'All Devices' ? null : this.selected);
    },
    device: function () {
      if (this.device == null) {
        this.selected = { name: 'All Devices', id: 'All Devices' };
      }
    }
  },

  async created() {
    this.loading = true;

    const organizationId = (await getAuth().sessionUser()).organizationUuid;

    const params = {
      pageSize: 50,
      organizationId
    };

    try {
      const response = await DeviceServiceV2.listDevices(params);

      response.data.forEach((event) => {
        this.options.push(event);
      });
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  },

  methods: {
    reset() {
      this.selected = null;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
