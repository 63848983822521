export const OrderTypes = {
  // ALWAYS ADD NEW ORDER TYPES TO THE END TO KEEP numeric integer increasing.
  // NOTE: THERE IS NO ENUM = 0
  CUSTOMER_PAYMENTS: 1,
  CUSTOMER_REFUNDS: 2,
  CUSTOMER_PAYOUTS: 3,
  ORGANIZATION_PAYOUTS: 4,
  RAFFLEBOX_FEES: 5,
  // NOTE: GOLDRUSH_POS_PAYMENT_CASH = 6
  GOLDRUSH_POS_PAYMENT_CASH: 6,
  GOLDRUSH_POS_PAYMENT_CREDIT: 7,
  GOLDRUSH_POS_REFUND_CASH: 8,
  GOLDRUSH_POS_REFUND_CREDIT: 9,
  GOLDRUSH_POS_SUBSCRIPTION_PAYMENT_CASH: 10,
  GOLDRUSH_POS_SUBSCRIPTION_PAYMENT_CREDIT: 11,
  GOLDRUSH_POS_SUBSCRIPTION_REFUND_CASH: 12,
  GOLDRUSH_POS_SUBSCRIPTION_REFUND_CREDIT: 13,
  // NOTE: GOLDRUSH_ONLINE_PAYMENT_CASH = 14
  GOLDRUSH_ONLINE_PAYMENT_CASH: 14,
  GOLDRUSH_ONLINE_PAYMENT_CREDIT: 15,
  GOLDRUSH_ONLINE_REFUND_CASH: 16,
  GOLDRUSH_ONLINE_REFUND_CREDIT: 17,
  GOLDRUSH_ONLINE_SUBSCRIPTION_PAYMENT_CASH: 18,
  GOLDRUSH_ONLINE_SUBSCRIPTION_PAYMENT_CREDIT: 19,
  GOLDRUSH_ONLINE_SUBSCRIPTION_REFUND_CASH: 20,
  GOLDRUSH_ONLINE_SUBSCRIPTION_REFUND_CREDIT: 21,
  // NOTE: RAFFLE_ONLINE_PAYMENT_CASH = 22
  RAFFLE_ONLINE_PAYMENT_CASH: 22,
  RAFFLE_ONLINE_PAYMENT_CREDIT: 23,
  RAFFLE_ONLINE_REFUND_CASH: 24,
  RAFFLE_ONLINE_REFUND_CREDIT: 25,
  // NOTE: RAFFLE_HYBRID_PAYMENT_CASH = 26
  RAFFLE_HYBRID_PAYMENT_CASH: 26,
  RAFFLE_HYBRID_PAYMENT_CREDIT: 27,
  RAFFLE_HYBRID_REFUND_CASH: 28,
  RAFFLE_HYBRID_REFUND_CREDIT: 29,
  // NOTE: RAFFLE_HYBRID_PAYMENT_CASH = 30
  RAFFLE_IN_VENUE_PAYMENT_CASH: 30,
  RAFFLE_IN_VENUE_PAYMENT_CREDIT: 31,
  RAFFLE_IN_VENUE_REFUND_CASH: 32,
  RAFFLE_IN_VENUE_REFUND_CREDIT: 33,
  // NOTE: RAFFLEBOX_BANK = 34
  RAFFLEBOX_BANK: 34,
  RAFFLEBOX_STRIPE: 35,
  STRIPE_FEES: 36,
  RAFFLE_ONLINE_PAYMENT_CHEQUE: 37,
  // NOTE: RAFFLE_ONLINE_REFUND_CHEQUE = 38
  RAFFLE_ONLINE_REFUND_CHEQUE: 38,
  RAFFLE_ONLINE_PAYMENT_MONEY_ORDER: 39,
  RAFFLE_ONLINE_REFUND_MONEY_ORDER: 40,
  // NOTE: RAFFLE_IN_VENUE_PAYMENT_DEBIT = 41
  RAFFLE_IN_VENUE_PAYMENT_DEBIT: 41,
  RAFFLE_IN_VENUE_REFUND_DEBIT: 42
};

export const retrieveNumericOrderType = (orderType) => {
  if (orderType && typeof orderType === 'string') {
    return OrderTypes[orderType];
  } else {
    return null;
  }
};

export const mapOrderTypeToLabel = (orderType) => {
  if (orderType && typeof orderType === 'string') {
    switch (orderType) {
      case 'RAFFLE_ONLINE_PAYMENT_CASH':
        return 'cash';

      case 'RAFFLE_ONLINE_PAYMENT_CHEQUE':
        return 'cheque';

      case 'RAFFLE_ONLINE_PAYMENT_MONEY_ORDER':
        return 'money order';

      default:
        return null;
    }
  } else {
    return null;
  }
};
