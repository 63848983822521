<template>
  <GenericModal size="sm" @modal-closed="modalClosed" :errorMessage="errorMessage">
    <template #openButton="{ openModal }">
      <div>
        <BaseButton variant="secondary-outline" @click.native="openModal"> Process Batch </BaseButton>
      </div>
    </template>
    <template #header>
      <div class="text-lg rb-modal__title">Process Batch</div>
    </template>
    <div v-if="!success">
      <template>
        <p class="mb-5">
          Enter the ID of the batch you wish to process below. We'll load a summary for you to confirm you have the
          correct batch before processing.
        </p>
      </template>
      <template>
        <TextInput ref="batchInput" label="Batch ID" name="batch-id" @input="debounceInput()" v-model="batchInput" />
      </template>

      <div v-if="batchOrdersSummary">
        <hr class="mb-4" />
        <div class="px-2">
          <div class="flex justify-between">
            <div>
              <a class="font-bold underline pb-2 link-color" :href="`/orders?batchId=${batchInput}`">
                <strong>{{ batchOrdersSummary.batchId }}</strong>
              </a>
              <p class="mt-2">
                {{
                  `${format(new Date(batchOrdersSummary.startDate), 'LLL. d, yyyy')} - ${format(
                    new Date(batchOrdersSummary.endDate),
                    'LLL. d, yyyy'
                  )} `
                }}
              </p>
            </div>
            <div>
              <p class="text-right pb-2">{{ `${batchOrdersSummary.ordersTotalCount} orders` }}</p>
              <p class="text-right">
                {{ formatCurrency(batchOrdersSummary.ordersTotalCents / 100, { precision: 2, currency: 'CAD' }) }}
              </p>
            </div>
          </div>
          <p class="font-bold mt-4">Orders Breakdown</p>
          <hr class="mt-1 mb-4" />
          <div class="flex justify-between">
            <div>
              <p class="mb-2.5" v-if="batchOrdersSummary.ordersPendingCount > 0">
                {{ `${batchOrdersSummary.ordersPendingCount} orders` }}
              </p>
              <p class="mb-2.5" v-if="batchOrdersSummary.ordersErroredCount > 0">
                {{ `${batchOrdersSummary.ordersErroredCount} orders (will not be processed)` }}
              </p>
              <p class="mb-2.5" v-if="batchOrdersSummary.ordersActiveCount > 0">
                {{ `${batchOrdersSummary.ordersActiveCount} orders` }}
              </p>
              <p class="mb-2" v-if="batchOrdersSummary.ordersVoidedCount > 0">
                {{ `${batchOrdersSummary.ordersVoidedCount} orders` }}
              </p>
            </div>
            <div>
              <p class="text-right mb-2" v-if="batchOrdersSummary.ordersPendingCount > 0">
                <Badge variant="default" label="Pending" />
              </p>
              <p class="text-right mb-2" v-if="batchOrdersSummary.ordersErroredCount > 0">
                <Badge variant="danger" label="Failed" />
              </p>
              <p class="text-right mb-2" v-if="batchOrdersSummary.ordersActiveCount > 0">
                <Badge variant="success" label="Success" />
              </p>
              <p class="text-right" v-if="batchOrdersSummary.ordersVoidedCount > 0">
                <Badge variant="tertiary" label="voided" />
              </p>
            </div>
          </div>

          <div class="mt-4" v-if="batchOrdersSummary.orderTypes.length">
            <Badge
              class="mr-2"
              variant="secondary"
              :label="label"
              v-for="(label, index) of batchOrdersSummary.orderTypes"
              :key="index"
            />
          </div>
        </div>
        <hr class="mt-4" />
      </div>

      <div v-if="batchSummaryNotFound">
        <hr class="mb-4" />
        <div v-if="loading" class="flex justify-center items-center">
          <LoadingSpinner />
        </div>
        <p v-else class="text-center">{{ noDataMessage }}</p>

        <hr class="mt-4 mb-4" />
      </div>
    </div>
    <div>
      <p class="mb-5" v-if="success">
        Successfully processing batch <span class="font-bold underline">{{ `#${batchInput}` }}</span
        >. This will take some time and happen in the background. You may close this modal.
      </p>
    </div>
    <template slot="footer" slot-scope="{ closeModal }">
      <div class="flex py-1">
        <BaseButton v-if="!success" variant="secondary" @click.native="cancel(closeModal)" class="mr-2">{{
          'Cancel'
        }}</BaseButton>

        <BaseButton v-else variant="secondary" @click.native="closeModalHandler(closeModal)" class="mr-2">{{
          'Close'
        }}</BaseButton>

        <BaseButton
          :disabled="batchSummaryNotFound"
          :loading="loading"
          :class="success ? 'hidden' : ''"
          variant="success"
          @click.native="submit()"
        >
          Process Batch
        </BaseButton>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import { format } from 'date-fns';
import GenericModal from '@/components/modals/GenericModal.vue';
import TextInput from '@/components/inputs/TextInput';
import Badge from '@/components/rbComponents/Badge.vue';
import { debounce } from 'lodash';
import BatchService from '@/lib/batch-process-orders';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  name: 'BatchProcessButton',
  components: {
    GenericModal,
    TextInput,
    Badge,
    LoadingSpinner,
    BaseButton
  },
  watch: {
    batchInput() {
      this.debounceInput();
    }
  },
  data() {
    return {
      modalConfig: {
        showHeaderClose: true,
        showCloseModalButton: false
      },
      errorMessage: null,
      batchInput: this.value,
      success: false,
      loading: false,
      format: format,
      showProcessBatchOrders: false,
      batchOrdersSummary: null,
      batchOrdersSummaryError: null,
      batchSummaryNotFound: true,
      organizationUuid: null
    };
  },
  async mounted() {
    const sessionUser = await getAuth().sessionUser();
    this.organizationUuid = sessionUser.organizationUuid;
  },
  computed: {
    noDataMessage() {
      return this.batchInput && !this.batchOrdersSummary ? 'Batch not found' : 'No batch selected';
    }
  },
  methods: {
    closeModalHandler(closeModal) {
      this.success = false;
      closeModal();
      this.$emit('triggerRefresh');
      this.resetBatchInput();
    },
    cancel(closeModal) {
      this.success = false;
      closeModal();
      this.resetBatchInput();
    },
    modalClosed() {
      this.errorMessage = null;
      this.success = false;
      this.resetBatchInput();
    },
    async submit() {
      try {
        this.loading = true;
        await BatchService.batchProcessOrders(this.batchInput, this.organizationUuid);
        this.loading = false;
        this.success = true;
        this.errorMessage = null;
      } catch (error) {
        this.errorMessage = `Error processing batch: ${this.parseError(error).message.replace(/[-_]/g, ' ')}`;
        this.loading = false;
        this.success = false;
      }
    },
    debounceInput: debounce(async function () {
      const params = {
        organizationId: this.organizationUuid
      };

      const response = await BatchService.retrieveBatchOrderSummary(this.batchInput, params);

      if (response instanceof Error) {
        this.batchOrdersSummary = null;
        this.batchSummaryNotFound = true;

        return;
      }

      this.batchSummaryNotFound = false;
      this.batchOrdersSummary = response.mappedSummary;
    }, 500),
    resetBatchInput() {
      this.batchInput = '';
      this.batchOrdersSummary = null;
    }
  }
};
</script>

<style scoped>
.link-color {
  color: #2c3e50;
}
</style>
