<template>
  <div>
    <h6 class="mb-2 font-semibold">Customer Information</h6>
    <div>
      <!-- Row 1 -->
      <div class="flex gap-4">
        <TextInput
          ref="firstName"
          label="First Name"
          name="first-name"
          :required="required"
          v-model="customerInformation.firstName"
          class="flex-1 relative"
        />
        <TextInput
          ref="lastName"
          label="Last Name"
          name="last-name"
          :required="required"
          min="2"
          v-model="customerInformation.lastName"
          class="flex-1"
        />
        <b-form-group label-for="title" :invalid-feedback="veeErrors.first('title')" class="flex-1">
          <template v-slot:label>Title</template>
          <b-form-select
            name="title"
            v-model="customerInformation.title"
            :options="titleOptions"
            :state="validateState('title')"
            data-vv-as="title"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-for="age" :invalid-feedback="veeErrors.first('age')" class="flex-1">
          <template v-slot:label>Age</template>
          <b-form-select
            name="age"
            v-model="customerInformation.age"
            :options="ageRangeOptions"
            :state="validateState('age')"
            data-vv-as="age"
          ></b-form-select>
        </b-form-group>
      </div>

      <!-- Row 2 -->
      <div class="flex gap-4">
        <EmailInput ref="email" label="Email" name="email" v-model="customerInformation.email" class="flex-1" />
        <TextInput
          ref="phone"
          label="Phone"
          name="phone"
          :required="required"
          numeric="true"
          max="10"
          min="10"
          v-model="customerInformation.phone"
          class="flex-1"
        />
      </div>

      <!-- Row 3 -->
      <div class="flex gap-4">
        <TextInput
          ref="additionalNames"
          label="Additional Names"
          name="additional-names"
          v-model="customerInformation.additionalNames"
          class="w-1/2"
        />
      </div>

      <div>
        <h6 class="my-4 font-semibold">Billing Address</h6>

        <!-- Row 4 -->
        <div class="flex gap-4">
          <TextInput
            ref="address"
            label="Address"
            name="address"
            v-model="customerInformation.address"
            class="flex-1"
          />
          <TextInput ref="city" label="City" name="city" v-model="customerInformation.city" class="flex-1" />
        </div>

        <!-- Row 5 -->
        <div class="flex gap-4">
          <ProvinceDropdownInput
            v-if="country"
            ref="province"
            name="province"
            :label="provinceStateView.dropdownLabel"
            v-model="customerInformation.province"
            :required="required"
            :country="country"
            class="flex-1"
          />
          <PostalInput
            v-if="country === 'CA'"
            ref="postalCode"
            label="Postal Code"
            name="postal-code"
            v-model="customerInformation.postalCode"
            :province="customerInformation.province"
            class="flex-1"
          />
          <ZipInput
            v-if="country === 'US'"
            ref="postalCode"
            label="ZIP Code"
            name="postal-code-billing"
            :required="required"
            v-model="customerInformation.postalCode"
            :province="customerInformation.province"
            class="flex-1"
          />
        </div>
      </div>

      <!-- Shipping Address fields -->
      <div>
        <!-- checkbox to enable shipping fields -->
        <div class="flex items-center gap-2">
          <b-form-checkbox
            id="input-shipping-enabled"
            v-model="customerInformation.shippingEnabled"
            name="input-shipping-checkbox"
            class="flex-1"
          >
            Use billing information as shipping information
          </b-form-checkbox>
        </div>
        <div v-show="!customerInformation.shippingEnabled">
          <h6 class="mb-4 mt-6 font-semibold">Shipping Address</h6>

          <!-- Row 1 -->
          <div class="flex gap-4">
            <TextInput
              ref="shippingAddress"
              label="Address"
              name="shipping-address"
              v-model="customerInformation.shippingAddressLine1"
              class="flex-1"
            />
            <TextInput
              ref="shippingCity"
              label="City"
              name="shipping-city"
              v-model="customerInformation.shippingCity"
              class="flex-1"
            />
          </div>

          <!-- Row 2 -->
          <div class="flex gap-4">
            <ProvinceDropdownInput
              v-if="country"
              ref="shippingProvince"
              :label="provinceStateView.dropdownLabel"
              name="shipping-province"
              v-model="customerInformation.shippingState"
              :required="!customerInformation.shippingEnabled ? required : false"
              :country="country"
              class="flex-1"
            />
            <PostalInput
              v-if="country === 'CA'"
              ref="shippingPostalCode"
              label="Postal Code"
              name="shipping-postal-code"
              v-model="customerInformation.shippingPostal"
              :province="customerInformation.shippingState"
              class="flex-1"
            />
            <ZipInput
              v-if="country === 'US'"
              ref="shippingPostalCode"
              label="ZIP Code"
              name="shipping-postal-code-billing"
              v-model="customerInformation.shippingPostal"
              :province="customerInformation.shippingState"
              class="flex-1"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4 mt-4">
        <h6 class="font-semibold">Comments</h6>
        <b-form-group label-for="input-comments" :invalid-feedback="veeErrors.first('input-comments')">
          <b-form-textarea
            ref="comments"
            name="input-comments"
            id="textarea"
            v-model="customerInformation.comment"
            placeholder="Enter comment..."
            :state="validateState('input-comments')"
            rows="6"
          ></b-form-textarea>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/inputs/TextInput';
import EmailInput from '@/components/inputs/EmailInput';
import PostalInput from '@/components/inputs/PostalInput';
import ZipInput from '@/components/inputs/ZipInput';

import ProvinceStateService from '@/lib/province-state-view-service';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import ProvinceDropdownInput from '@/components/inputs/ProvinceDropdownInput';

const titleOptions = [
  { value: null, text: '--- Select a title ---' },
  { value: 'Mr.', text: 'Mr.' },
  { value: 'Mrs.', text: 'Mrs.' },
  { value: 'Ms.', text: 'Ms.' },
  { value: 'Mx.', text: 'Mx.' },
  { value: 'Miss', text: 'Miss' },
  { value: 'Dr.', text: 'Dr.' }
];

const ageRanges = [
  { value: null, text: '--- Select an age range ---' },
  { value: '18-34', text: '18-34' },
  { value: '35-49', text: '35-49' },
  { value: '50-64', text: '50-64' },
  { value: '65-74', text: '65-74' },
  { value: '75+', text: '75+' }
];

export default {
  components: {
    TextInput,
    EmailInput,
    ProvinceDropdownInput,
    PostalInput,
    ZipInput
  },
  props: {
    editCustomerInformation: {
      type: Object,
      required: false
    },
    eventId: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ageRangeOptions: ageRanges,
      titleOptions: titleOptions,
      customerInformation: {
        firstName: null,
        lastName: null,
        title: null,
        age: null,
        address: null,
        city: null,
        province: null,
        postalCode: null,
        email: null,
        phone: null,
        additionalNames: null,
        comment: null,
        shippingAddressLine1: null,
        shippingCity: null,
        shippingState: null,
        shippingPostal: null,
        shippingEnabled: false
      },
      country: null,
      provinceStateView: {},
      provinceStateOptions: {}
    };
  },
  provide() {
    return { parentValidator: this.$validator };
  },
  async created() {
    const organization = await OrganizationServiceV2.retrieveOrganization(this.$store.state.user.organizationUuid);
    this.country = organization.country;
    this.provinceStateView = ProvinceStateService.getDefaultView(this.country);
    this.provinceStateOptions = this.provinceStateView.dropdownOptions;
  },
  watch: {
    customerInformation: {
      handler() {
        this.$emit('updateCustomerInformation', this.customerInformation);
      },
      deep: true
    },
    editCustomerInformation: {
      handler() {
        if (this.editCustomerInformation !== this.customerInformation) {
          this.customerInformation = { ...this.editCustomerInformation };
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.editCustomerInformation) {
      this.customerInformation = { ...this.editCustomerInformation };
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    customValidation(silent = false) {
      const firstName = this.$refs.firstName.isValid(silent);
      const lastName = this.$refs.lastName.isValid(silent);
      const phone = this.$refs.phone.isValid(silent);
      const province = this.$refs.province.isValid(silent);
      let shippingProvince = false;

      if (!this.customerInformation.shippingEnabled) {
        shippingProvince = this.$refs.shippingProvince.isValid(silent);
        return firstName && lastName && province && shippingProvince && phone;
      }

      return firstName && lastName && province && phone;
    },
    resetValidation() {
      this.$refs.firstName.$validator.reset();
      this.$refs.lastName.$validator.reset();
      this.$refs.phone.$validator.reset();
      this.$refs.province.$validator.reset();
      this.$refs.email.$validator.reset();
      this.$refs.additionalNames.$validator.reset();
      this.$refs.address.$validator.reset();
      this.$refs.city.$validator.reset();
      this.$refs.postalCode.$validator.reset();
      this.$refs.shippingProvince.$validator.reset();
      this.$validator.reset();
    }
  }
};
</script>

<style lang="scss">
.custom-control {
  padding-left: 2rem;
}

.custom-checkbox {
  .custom-control-label::before {
    top: 0;
    border: 1px solid #d5d7d7;
    border-radius: 0.3rem;
    height: 1.4rem;
    width: 1.4rem;
    left: -2rem;
  }

  .custom-control-label::after {
    top: 0;
    border: 1px solid #d5d7d7;
    border-radius: 0.3rem;
    height: 1.4rem;
    width: 1.4rem;
    left: -2rem;
  }

  .custom-control-input:checked {
    & ~ .custom-control-label::before {
      background-color: #5db966;
    }

    & ~ .custom-control-label::after {
      background-color: #5db966;
    }
  }

  .custom-control-input:disabled:checked {
    & ~ .custom-control-label::before {
      background-color: #9f9f9f;
      border-color: #c1c1c1;
    }

    & ~ .custom-control-label::after {
      background-color: #9f9f9f;
      border-color: #c1c1c1;
    }
  }
}

.disabled {
  color: #6c757d;
}
</style>
