<template>
  <b-card>
    <b-row>
      <b-col sm="2">
        <OrderStatusSelect :status="statusSelected" @orderStatusFilter="onOrderStatusFilter" />
      </b-col>

      <b-col sm="7" v-if="showEventFilter">
        <EventSelectV2 :event="selectedEvent" ref="event" @eventSelected="onEventFilter" />
      </b-col>
    </b-row>

    <b-row class="filter-row">
      <b-col>
        <DateRange ref="date" @datesChanged="onDateFilter" @resetDates="resetDates" />
      </b-col>
    </b-row>

    <b-row v-if="showStationFilter" class="filter-row">
      <b-col sm="5">
        <StationSelect :station="stationSelected" @stationSelected="onStationFilter" />
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col sm="5">
        <DeviceSelect v-if="showDeviceFilter" :device="deviceSelected" @deviceSelected="onDeviceFilter" />
      </b-col>
    </b-row>

    <b-row class="filter-row">
      <b-col>
        <b-button-group>
          <b-button @click="onFiltersChanged" variant="outline-secondary">Apply</b-button>
          <b-button @click="onFiltersCleared" variant="outline-danger">Reset</b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import OrderStatusSelect from '@/components/OrderStatusSelect';
import EventSelectV2 from '@/components/EventSelectV2';
import DateRange from '@/components/DateRange';
import StationSelect from '@/components/StationSelect';
import DeviceSelect from '@/components/DeviceSelect';
import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  components: { OrderStatusSelect, EventSelectV2, DateRange, StationSelect, DeviceSelect },
  props: ['defaultStartDateTime', 'defaultEndDateTime'],
  data() {
    return {
      statusSelected: null,
      deviceSelected: null,
      stationSelected: null,
      selectedEvent: null,
      showStationFilter: false,
      showEventFilter: false,
      goldrushEvent: false,
      showDeviceFilter: false
    };
  },
  computed: {
    filters() {
      return this.$store.getters.getFilters;
    }
  },

  async mounted() {
    this.showStationFilter = await unleashFeatureEnabled(UnleashKeys.GoldrushStationFilter);
    this.showEventFilter = !this.$route.query.eventId;
    this.deviceSelected = this.filters.device || null;
    this.statusSelected = this.filters.status || null;
    this.stationSelected = this.filters.station || null;
    this.selectedEvent = this.filters.event || null;
    this.showDeviceFilter = await unleashFeatureEnabled(UnleashKeys.ViewDevices);
  },

  methods: {
    onOrderStatusFilter(orderStatus) {
      this.$store.dispatch('setFilters', { status: orderStatus === 'ALL' ? null : orderStatus });
      this.statusSelected = this.filters.status || null;
    },
    onEventFilter(event) {
      this.$store.dispatch('setFilters', { event: event?.id === 'All' ? null : event });
      this.selectedEvent = this.filters.event || null;
    },
    onDeviceFilter(device) {
      this.$store.dispatch('setFilters', { device: device === 'ALL' ? null : device });
      this.deviceSelected = this.filters.device || null;
    },
    onDateFilter(dateRange) {
      this.$store.dispatch('setFilters', {
        startDateTime: dateRange.startDateTime,
        endDateTime: dateRange.endDateTime
      });
    },
    onStationFilter(station) {
      this.$store.dispatch('setFilters', {
        station: station === 'ALL' ? null : station
      });
      this.stationSelected = this.filters.station || null;
    },
    onFiltersChanged() {
      this.$emit('orderFilterChanged', this.filters);
    },
    onFiltersCleared() {
      this.$store.dispatch('setFilters', {
        status: null,
        event: null,
        startDateTime: null,
        endDateTime: null,
        station: null,
        device: null
      });
      this.selectedEvent = null;
      this.statusSelected = null;
      this.stationSelected = null;
      this.deviceSelected = null;

      this.resetDates();
      this.$store.dispatch('setSearchInput', '');
      this.$emit('orderFilterChanged', this.filters);
    },
    resetDates() {
      this.$refs.date.reset();
      this.$emit('resetDates');
    }
  }
};
</script>

<style scoped>
.filter-row {
  margin-top: 1rem;
}
</style>
