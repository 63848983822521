import Axios from '@/axios';

const batchUrl = `/order-service/v2/batches`;

const batchProcessOrders = async (batchId, organizationId) => {
  const response = await Axios.patch(`${batchUrl}/${batchId}`, {
    status: 'ACTIVE',
    organizationId
  });

  return response.data.data;
};

const retrieveBatchOrderSummary = async (batchOrderId, params) => {
  try {
    const response = await Axios.get(`${batchUrl}/${batchOrderId}`, {
      params
    });

    return response.data.data;
  } catch (error) {
    return error;
  }
};

export default {
  batchProcessOrders,
  retrieveBatchOrderSummary
};
