<template>
  <div>
    <h6 class="font-semibold mb-2">Add Order Item</h6>
    <p class="mb-2">
      Once you select a raffle from the dropdown, select how many of each ticket package you would like to add to the
      order and submit. This constitutes as one order item. You can run through this form more than once to add order
      items across multiple raffles.
    </p>
    <b-form-group label-for="select-event" class="mb-5 w-72">
      <template v-slot:label>Raffle <span class="text-danger">*</span> </template>
      <b-form-select
        name="select-event"
        v-model="selectedEventId"
        :options="visibleOptions"
        :state="validateState('select-event')"
        data-vv-as="raffle"
      ></b-form-select>
    </b-form-group>
    <Alert v-if="errorMessage" variant="red" icon="exclamation">{{ errorMessage }}</Alert>
    <GenericTable
      :columns="columns"
      :data="selectedTicketPackages"
      :loading="loading"
      stacked
      emptyMessage="Select a raffle above to see the available ticket packages"
    >
      <template #number="{ row }">
        <p>{{ row.numTickets }} for ${{ row.price }}</p>
      </template>
      <template #quantity="{ row }">
        <b-form-group :label-for="row.id" invalid-feedback="Invalid Input" class="w-20 m-0">
          <b-form-input
            :id="row.id"
            :name="row.id"
            v-model="quantities[row.id]"
            v-validate="'numeric|min:0|max:10'"
            :state="validateState(row.id)"
            :data-vv-as="row.id"
          ></b-form-input>
        </b-form-group>
      </template>
      <template #total="{ row }">
        <p>{{ formatCurrency(quantities[row.id] * row.price || 0, { precision: 2 }) }}</p>
      </template>
    </GenericTable>
    <div class="block text-right" v-if="selectedTicketPackages.length">
      <p class="font-bold pb-4">Total: {{ formatCurrency(total, { precision: 2 }) }}</p>
      <BaseButton
        class="mr-0 ml-auto"
        variant="success-outline"
        @click.native="addToCart()"
        :disabled="disableAddToCart"
      >
        <span>Add to Cart</span>
      </BaseButton>
    </div>
  </div>
</template>
<script>
import GenericTable from '@/components/GenericTable';
import Alert from '@/components/ui/Alert';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  components: {
    Alert,
    GenericTable,
    BaseButton
  },
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedEventId: null,
      selectedTicketPackages: [],
      columns: [
        {
          name: 'number',
          label: 'Ticket Packages',
          classes: 'w-8/12'
        },
        {
          name: 'quantity',
          label: 'Quantity',
          classes: 'w-auto'
        },
        {
          name: 'total',
          label: 'Total',
          classes: 'w-auto',
          isCurrency: true
        }
      ],
      loading: false,
      quantities: {},
      errorMessage: null
    };
  },
  computed: {
    total() {
      return this.selectedTicketPackages.reduce((acc, pkg) => {
        return acc + this.quantities[pkg.id] * pkg.price;
      }, 0);
    },
    disableAddToCart() {
      // quantities needs at least one bought
      return Object.values(this.quantities).every((quantity) => quantity === 0);
    },
    visibleOptions() {
      return this.options.filter((event) => !event.disabled);
    }
  },
  watch: {
    selectedEventId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleEventChange(newValue);
      }
    }
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        const isValid = !this.veeErrors.has(ref);
        return isValid;
      }
      return null;
    },
    handleEventChange(selectedEventId) {
      this.resetQuantities();
      this.updateSelectedTicketPackages(selectedEventId);
    },
    resetQuantities() {
      this.quantities = {};
    },
    updateSelectedTicketPackages(selectedEventId) {
      const event = this.options.find((event) => event.value === selectedEventId);
      if (event && event.ticketPackages) {
        this.selectedTicketPackages = event.ticketPackages;
        event.ticketPackages.forEach((pkg) => {
          this.$set(this.quantities, pkg.id, 0);
        });
      } else {
        this.selectedTicketPackages = [];
      }
    },
    async addToCart() {
      // validate the selectedTicketPackages for only positive values
      const formValid = await this.$validator.validateAll();

      if (!formValid) {
        this.errorMessage = 'Please enter a valid quantity for each ticket package';
        return;
      }

      const event = this.options.find((event) => event.value === this.selectedEventId);

      this.$emit('addToCart', event.event, this.selectedTicketPackages, this.quantities);
      this.selectedEventId = null;
    }
  }
};
</script>
